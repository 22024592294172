body {
  margin: 0;
  height: 100vh;
  min-height: 100vh;
  font-family: "Sora", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  height: 100%;
}

.App {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background-color: #0c0c91;
  width: 100%;
  flex: 0 0 80px;
}

.logo {
  width: auto;
  max-width: 40%;
  max-height: 60px;
}

.recruiters {
  display: flex;
  flex-grow: 1;
  margin-top: 2rem;
  margin-bottom: 4rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.avatar-container {
  margin-top: 2rem;
  display: flex;
}

.avatar {
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  margin-right: 1rem;
  object-fit: contain;
}

.info-container {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  justify-content: center;
}

.bold {
  font-weight: bold;
}

.mail-link {
  color: #0c0c91;
}

.linkedin {
  height: 25px;
  width: 25px;
  margin-left: 1rem;
}

.name-container {
  display: flex;
  align-items: center;
}

.footer-container {
  width: 100%;
  /* position: absolute; */
  /* bottom: 0; */
  padding-top: 2rem;
  border-top: 1px solid black;
}

.office-logo {
  max-width: 50px;
}

.office-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

b {
  font-weight: 700;
  display: block;
  color: #0c0c91;
  margin-bottom: 0.5rem;
}

.no-margin {
  margin: 0;
  font-size: 80%;
}

.office-container {
  display: flex;
  justify-content: space-between;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
}

.link-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
